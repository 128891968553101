.html {
  height: 100vh;
  width: 100vw;
}

body {
  background-color: black;
  height: 100%;
  width: 100%;
}

header {
  display: flex;
  justify-content: center;
  justify-self: center;
  width: 50vw;
  height: 7.5vh;
  margin: 5vh 0;
  background-color: black;
}

.header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 2vh 1vw;
  width: 100%;
  border: 1px solid lightgray;
  background-color: yellow;
  border-radius: 999px;
  align-items: center;
}

header a {
  background-color: black;
  color: yellow;
  text-decoration: none;
  border-radius: 999px;
  padding: 1vh 2vw;
  margin: 0 1vw;
  font-size: 1.2rem;
  font-weight: 600;
}

.logo {
  background-color: transparent;
  color: black;
}

.trade {
  color: lightgray;
}

.main-section {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.main-section h1 {
  font-size: 5rem;
  color: yellow;
  text-align: center;
  margin: 0;
}

.main-section pre {
  display: flex;
  font-size: 7rem;
  color: yellow;
  font-family: "Arial", sans-serif;
  margin: 0;
  padding: 0;
  margin-right: 10vw;
}